<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>
            {{ $t('My Gifts') }}
          </h1>
        </div>
      </div>
    </section>
    <div style="background-color: white; padding-top: 36px; padding-bottom: 150px">
      <div class="container">
        <!-- <button type="button" class="btn btn-info mb-3" v-b-modal.modal-1>{{ $t('Add New') }}</button> -->


        <!-- Modal -->
        <!-- <b-modal id="modal-1" title="Add New" hide-footer>



          <div class="col-md-5">
            <div class="mb-3">
              <label for="country" class="form-label">
                {{ $t("Phone") }} <b style="color: red;">*</b>
              </label>
              <vue-tel-input v-model.trim="body.phone" v-bind="{
                mode: 'international',
              }" :allCountries="allCountries"></vue-tel-input>
            </div>
          </div>
          <div class="col-md-5">
            <div class="mb-3">
              <label for="id" class="form-label">
                #ID <b style="color: red;">*</b></label>
              <input type="text" class="form-control" id="id" v-model.number="body.id" />
            </div>
          </div>
        </b-modal> -->

        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" style="width:100px">#</th>
              <th scope="col">{{ $t('First Name') }}</th>
              <th scope="col">{{ $t('Last Name') }}</th>
              <th scope="col">{{ $t('Payment Method') }}</th>
              <th scope="col">{{ $t('depoist') }}</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item of list">
              <th scope="row">{{ $codePadding(item.id) }}</th>
              <td>{{ item.fname }}</td>
              <td>{{ item.lname }}</td>
              <td>
                {{ item.paymentMethod }}
                -
                {{ item.usersId.paymentDone ? "And Done" : "But Not Complite" }}
              </td>
              <td>
                {{ item.price }} - {{ item.depoist }} =
                {{ item.price - item.depoist }}€
              </td>
              <td class="text-center">
                <router-link v-if="item.paymentDone" :to="'giftPrint/' + item.id">
                  <i class="fa fa-eye"></i>
                </router-link>
                <!-- <router-link
                  v-else
                  :to="
                    '/PaymentGift/' +
                      (Number(item.price) +
                        Number(item.recieverType == 'post' ? 3 : 0))
                  "
                >
                  <i class="fa fa-credit-card"></i>
                </router-link> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [],
      body: {
        id: null,
        phone: null,
      },
      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),
    };
  },

  methods: {},
  async created() {
    const user = JSON.parse(localStorage.userKB);

    this.$http.get("gifts?recieverphone=" + user.phone).then((res) => {
      this.list = res.data.data;
    });
  },
};
</script>

<style></style>
